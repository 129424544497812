<template>
	<div class="h-100 dis-flex-com po-r">
		<div class="po-a w-100 h-100">
			<van-nav-bar
			 v-if="$route.params.type != '1'"
			  title="我的"
			  left-text="返回"
			  left-arrow
			  @click-left="pushGo"
			/>
			<div class="flx-1 dis-flex-com login ft-sz-14">
				<div class="pd-x-15 bd-bott" @click="setClick('1')">
					<div class="dis-flex-row al-c pd-t-10 pd-b-10">
						<div class="mg-r-auto">
							姓名
						</div>
						<div class="pd-x-10 ft-co-99">
							{{userData.realname}}
						</div>
						<van-icon name="arrow" color="#d4d4d4" />
					</div>
				</div>
				<div class="pd-x-15 bd-bott" @click="sheetshow = true">
					<div class="dis-flex-row al-c pd-t-10 pd-b-10">
						<div class="mg-r-auto">
							性别
						</div>
						<div class="pd-x-10 ft-co-99">
							{{userData.sex == 1?'男':'女'}}
						</div>
						<van-icon name="arrow" color="#d4d4d4" />
					</div>
				</div>
				<div class="pd-x-15 bd-bott" @click="setClick('2')">
					<div class="dis-flex-row al-c pd-t-10 pd-b-10">
						<div class="mg-r-auto">
							电话
						</div>
						<div class="pd-x-10 ft-co-99">
							{{userData.usermobile}}
						</div>
						<van-icon name="arrow" color="#d4d4d4" />
					</div>
				</div>
				<div class="pd-x-15 bd-bott" @click="setClick('3')">
					<div class="dis-flex-row al-c pd-t-10 pd-b-10">
						<div class="mg-r-auto">
							邮箱
						</div>
						<div class="pd-x-10 ft-co-99">
							{{userData.useremail}}
						</div>
						<van-icon name="arrow" color="#d4d4d4" />
					</div>
				</div>
				<div class="pd-x-15 bd-bott" @click="urlClick">
					<div class="dis-flex-row al-c pd-t-10 pd-b-10">
						<div class="mg-r-auto">
							修改密码
						</div>
						<van-icon name="arrow" color="#d4d4d4" />
					</div>
				</div>
			</div>
		</div>
		<van-overlay :show="show" @click="show = false">
		  <div class="wrapper">
		    <div class="bk-co-ff pd-x-15 bd-r-10 pd-t-15 pd-b-15 w-100" @click.stop>
		      <div v-if="fromStatus == 1">
				  <van-field v-model="userData.realname" placeholder="请输入真实姓名" />
			  </div>
			  <div v-if="fromStatus == 2">
			  		<van-field v-model="userData.usermobile" placeholder="请输入手机号码" />
			  </div>
			  <div v-if="fromStatus == 3">
			  		<van-field v-model="userData.useremail" placeholder="请输入正确邮箱" />
			  </div>
			  <div class="pd-t-15">
			  	<button class="pd-y-10 w-100 ft-co-ff ft-sz-16 button" type="button" @click="codeSubmint">保存</button>
			  </div>
		    </div>
		  </div>
		</van-overlay>
		<van-action-sheet v-model="sheetshow" :actions="actions" @select="onSelect" />
	</div>
</template>

<script>
	import http from '../../lib/api.js'
	import util from '../../lib/util.js'
	import { Toast } from 'vant';
	var md5 = require('md5');
	var {adminGeneral} = http
	export default {
		name: 'Statistical',
		components: {},
		data() {
			return {
				userData:{},
				show:false,
				sheetshow:false,
				fromStatus:'',
				actions: [{ name: '男' }, { name: '女' }],
			}
		},
		mounted() {
			this.userInfo();
		},
		methods: {
			setClick(val){
				this.fromStatus = val;
				this.show = true;
			},
			onSelect(e){
				this.setuserInfo({
					sex:e.name == '男' ? 1: 0
				})
				this.sheetshow = false;
			},
			codeSubmint(){
				this.show = false;
				var obj = {}
				if(this.fromStatus == 1){
					if(this.userData.realname == ''){
						this.userInfo();
						return Toast('真实姓名不能为空');
					}
					obj.realname = this.userData.realname
				}else if(this.fromStatus == 2){
					var regEmail = /^[1][0-9]{10}$/
					if(this.userData.usermobile == '' || !regEmail.test(this.userData.usermobile)){
						this.userInfo();
						return Toast('手机号码格式不正确');
					}
					obj.usermobile = this.userData.usermobile
				}else if(this.fromStatus == 3){
					var regEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
					if (this.userData.useremail == '' || !regEmail.test(this.userData.useremail)) {
						this.userInfo();
						return Toast('邮箱格式不正确');
					}
					obj.useremail = this.userData.useremail
				}
				this.setuserInfo(obj)
			},
			userInfo() {/*获取用户信息*/
				var s = this;
				adminGeneral.userInfo.http({
					"companyid": s.$store.state.companyid,
					"userid": s.$store.state.user.userid,
				}).then(res => {
					if (res.getret === 0) {
						s.userData = res.info.user
					}
				})
			},
			setuserInfo(val) {/*设置用户信息*/
				var s = this;
				adminGeneral.setuserInfo.http({
					"info":val
				}).then(res => {
					if (res.getret === 0) {
						s.userInfo();
						Toast(res.msg);
					}
				})
			},
			pushGo(){/*返回*/
				this.$router.go(-1);
			},
			urlClick() { /*路由跳转*/
				this.$router.push('/modify')
			},
		}
	}
</script>
<style scoped>
	.button {border: none;color: #FFFFFF;border-radius: 30px;background: -webkit-linear-gradient(#f7514d, #fd704d);}
	button:focus {outline: none !important;}
	.wrapper {
	    display: flex;
	    align-items: center;
	    justify-content: center;
		padding:0 15px;
		border-radius:10px;
	    height: 100%;
	  }
</style>
